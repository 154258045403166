<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Добавить маршрут в договор № {{ item.vccarriercontractsDocnum }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="onClose"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Routes
              :items="routes"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :routes="routes"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllRoutes"
              @onRemove="removeRoute"
            ></Routes>
          </v-tab-item>

          <v-tab-item>
            <AllRoutes
              :items="allRoutes"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :routes="routes"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllRoutes>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import Routes from "./components/Routes";
  import AllRoutes from "./components/AllRoutes";
  import GeneralServices from "@/services/GeneralServices";

  export default {
    name: "AddRoute",
    components: {
      AllRoutes,
      Routes,
    },
    props: {
      collection: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      dateWork: null,
      dialog: true,
      item: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      allRoutes: [],
      routes: [],
      startContractDt: null,
      endContractDt: null,
      contractId: null,
    }),
    created() {
      this.item = this.collection.master.selected;
      this.startContractDt = this.item.vccarriercontractsStartcontractdt;
      this.endContractDt = this.item.vccarriercontractsEndcontractdt;
      this.contractId = this.item.vccarriercontractsId;

      this.dateWork = $utils.formatDate(
        new Date(Date.now()),
        "YYYY-MM-DD HH:mm:ss"
      );

      this.loadingRefs = true;
      this.getList();
      this.loadingRefs = false;
    },
    methods: {
      onClose() {
        this.dialog = !this.dialog;
      },
      cancelAdd() {
        this.tab = 0;
      },
      showAllRoutes() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;

        items.forEach((item) => {
          const index = this.routes.findIndex((route) => {
            return route.id === item.id;
          });

          if (index === -1) {
            this.routes.push(item);
          }
        });
      },
      removeRoute(item) {
        this.routes = this.routes.filter((route) => route.id !== item.id);
      },
      confirmAdd() {
        this.loadingConfirm = true;
        this.addRoute(this.routes);
      },
      async getRoutesByContracts(carrierContractId, dateWork) {
        const requestContracts = {
          type: "core-read",
          query: `sin2:/v:ffd7d02e-81e4-48bf-8066-c384c9385a84?filter=and(isnull(field(".vehicleId")),
                      eq(field(".contractId"),param("${carrierContractId}","id")),
                      lte(field(".routeId.route.verStart"),param("${dateWork}","date")),or(isnull(field(".routeId.route.verEnd")),
                      gte(field(".routeId.route.verEnd"),param("${dateWork}","date"))),
                      not(eq(field(".routeId.route.verStatus.Name"),param("Проект","string"))),or(isnull(field(".scheduleId")),
                      and(lte(field(".scheduleId.versionId.verStart"),param("${dateWork}","date")),
                      or(isnull(field(".scheduleId.versionId.verEnd")),
                      gte(field(".scheduleId.versionId.verEnd"),param("${dateWork}","date"))),
                      not(eq(field(".scheduleId.versionId.verStatus.Name"),param("Проект","string"))))))`,
        };

        try {
          const resp = await jet.http.post(requestContracts);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: "Не удалось загрузить список маршрутов",
            color: "warning",
          });
          console.error("AddRoute: getRoutesByContracts()", e);
          return;
        }
      },
      async getList() {
        let routes = [];
        let routeName = "";
        let routeCode = "";
        let versionStatus = "";
        let versionNumber = "";
        let routeId = "";
        let note = "";
        const carrierContractId = this.item.vccarriercontractsId;

        const routesByContracts = await this.getRoutesByContracts(
          carrierContractId,
          this.dateWork
        );
        const data = (routesByContracts && routesByContracts.data) || [];
        await Promise.all(
          data.map(async (it) => {
            if (routeId === it[19]) {
              return;
            }
            routeName = it[4];
            routeCode = it[3];
            routeId = it[19];
            note = it[15];
            versionNumber = it[5];
            versionStatus = it[6];

            routes.push({
              id: routeId,
              routeName: routeName,
              routeCode: routeCode,
              startDate: GeneralServices.formatDate(this.startContractDt),
              endDate: GeneralServices.formatDate(this.endContractDt),
              versionNumber: versionNumber,
              versionStatus: versionStatus,
              fullName: `${routeCode} ${routeName}`,
              note: "",
            });
          })
        );

        this.allRoutes = routes;
      },
      async addRoute(paramsArray) {
        let params = [];
        let id = "";
        let servicedroutes = [];

        const data = paramsArray || [];
        await Promise.all(
          data.map(async (it) => {
            id = it.id;
            servicedroutes.push(id);
          })
        );

        const optionsQuery = {
          type: "query",
          query:"d16cb8ba-8ffd-4753-89bc-5c7499bfa500.insertservicedroutesintocontract",
          params: {
            servicedroute: servicedroutes.toString(),
            contractid: this.contractId,
            tenantid: this.item._sec_attrsTenantid,
            userid: this.item._sec_attrsUserid,
            startdt: this.startContractDt,
            enddt: this.endContractDt,
            note: " ",
          },
        };

        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Добавление маршрутов в договор № ${this.item.vccarriercontractsDocnum} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: "Не удалось добавить маршрут",
            color: "warning",
          });
          console.error("AddRoute: addRoute()", e);
          return;
        }
        this.dialog = false;
        this.collection.refresh();
      },
    },
  };
</script>
